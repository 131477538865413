import {Injectable, NgZone} from '@angular/core';
import {ApiFormat} from '../../enums/api-format';
import {ApiService} from './api.service';
import {PushNotifications} from '@capacitor/push-notifications';
import {NavigationExtras} from '@angular/router';
import {AuthService} from './auth.service';
import {NavController, Platform} from '@ionic/angular';
import {filter, first} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PushNotificationsService {

    private logged: boolean;

    constructor(
        private readonly apiService: ApiService,
        private readonly authService: AuthService,
        private readonly platform: Platform,
        private readonly navCtrl: NavController
    ) {
        this.authService.authStatus.subscribe(authData => {
            this.logged = authData?.logged;
        });
    }

    pushToken(type: number, token: string){
        const body = {type, token};
        return this.apiService.post<any>('/api/notificationtokens', body);
    }

    clearPushCounter() {
        return this.apiService.get<any>('/api/notificationtoken/clear');
    }

    public async askPermission(): Promise<boolean> {
        let permStatus = await PushNotifications.checkPermissions();

        // If permission is set to prompt, ask user
        if (permStatus.receive === 'prompt') {
            permStatus = await PushNotifications.requestPermissions();
        }

        return permStatus.receive === 'granted';
    }

    public async register(): Promise<boolean> {
        const hasPermission = await this.askPermission();
        if (!hasPermission) {
            return false;
        }

        await PushNotifications.register();
        return true;
    }

    public async registerToken(): Promise<void> {
        this.authService.authStatus
            .pipe(filter(authData => authData?.logged && authData?.authDone))
            .pipe(first())
            .subscribe(async authData => {

                // We should check if we have permission to run push notifications
                const permStatus = await PushNotifications.checkPermissions();
                if (permStatus.receive === 'granted') {
                    // Register for push notifications
                    PushNotifications.register();
                }

                // Register the token
                const type = this.platform.is('ios') ? 1 : 2;
                PushNotifications.addListener('registration', token => {
                    this.pushToken(type, token.value).subscribe();
                });
            });
    }

    public async notificationTapped() {
        PushNotifications.addListener(
            'pushNotificationActionPerformed',
            (notification) => {
                // Only parse if data is a string
                let data = notification.notification.data;
                if (typeof data === 'string') {
                    try {
                        data = JSON.parse(notification.notification.data);
                    } catch (e) {
                        console.error('Error parsing notification data', e);
                        data = null;
                    }
                }
                // If data is not an object, just return
                if (data && typeof data === 'object') {
                    switch (data.link) {
                        case 'rating':
                            const feedbackId = data.data?.selectedFeedbackId;
                            if (feedbackId) {
                                this.navCtrl.navigateRoot('/feedbacks/rating/' + feedbackId);
                            } else {
                                console.error('Missing feedback id' + data);
                            }
                            break;
                        default:
                            break;
                    }
                }
                PushNotifications.removeAllDeliveredNotifications();
                if (this.logged) {
                    this.clearPushCounter().subscribe();
                }
            });
        PushNotifications.removeAllDeliveredNotifications();
        if (this.logged) {
            this.clearPushCounter().subscribe();
        }
    }
}
