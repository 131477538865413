/* tslint:disable:variable-name */
import {User} from './user';
import {Wallet} from './wallet';
import {BadgeFamily, BadgeType, Skill, SkillFamily, SkillFamilyGroup, SkillQuestion} from './skill';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import {Company, School} from "./learning-period";

export type Modality = 'opened_feedbacks' | 'closed_feedbacks' | 'note_partiel' | 'note_evenement' | 'note_projet' | 'note_devoir' | 'note_memoire' | 'note_jury';
export type UserType = 'tutor' | 'pros' | 'school_members' | 'external_pros' | 'associative';

export class Subject {
    title: string;

    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}

export class SkillBadgeCriterion {

    modality: Modality;
    closed_feedbacks_detail: UserType; // Type de répondant
    mandatory: boolean; // Ne pas traiter
    value: number; // open / close => numbre de feedbacks / si note => note min à obtenir (out of 20)
    subject: Subject;

    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
            this.subject = obj.subject ? new Subject(obj.subject) : null;
        }
    }
}

// Badge => historique de stat à un moment t

export class QualityIndex {
    index: 'A' | 'B' | 'C';
    indexLabel: string;
    allCount: number;
    proCount: number;

    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}

export class Score {
    average: string;
    question: SkillQuestion;
    selfCount: number;
    selfScore: string;
    weightedAverage: number;

    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
            this.question = new SkillQuestion(obj.question);
        }
    }
}

class Graph {
    averages: number[]; // Note globale
    feedbackAverages: number[]; // Note feedback
    schoolAverages: number[]; // Note école
    months: string[];
}

export class Training {
    id: number;
    title: string;
    description: string;
    lang: string;
    link: string;

    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}

export class SchoolGrade {
    title: string;
    average: number;

    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}

export class Recommendation {
    data: {
        id: string;
        content: string;
        author: string;
        status: 0 | 1;
        created_at: string;
    };
    recipientInfo: {
        firstname: string;
        lastname: string;
        type: string;
        company: Company;
        school: School;
    };

    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
        }
    }

    get authorType(): string {
        if (!this.recipientInfo) {
            return 'Inconnu';
        }
        switch (this.recipientInfo.type) {
            case 'ROLE_COSS_TUTOR':
                return _('recommendation.TUTOR');
            case 'ROLE_COSS_SCHOOL_REFERENT':
                return _('recommendation.SCHOOL_REFERENT'); // Corps pédagogique
            case 'ROLE_COSS_SCHOOL_ADMIN':
                return _('recommendation.SCHOOL_ADMIN'); // Administrateur de cours
            case 'ROLE_COSS_TEACHER':
                return _('recommendation.TEACHER'); // Enseignant
            case 'ROLE_COSS_STUDENT':
                return _('recommendation.STUDENT'); // Étudiant
            case 'RECIPIENT_PRO':
                return _('recommendation.PROFESSIONAL'); // Professionnel
            case 'RECIPIENT_EXTERNAL_PRO':
                return _('recommendation.EXTERNAL_PRO'); // Professionel job étudiant
            case 'RECIPIENT_SPORT_ASSO':
                return _('recommendation.SPORT_ASSO'); // 'Vie sportive et associative';
            default:
                return (this.recipientInfo.company?.name || 'Inconnu');
        }
    }

    get authorBusiness(): string {
        if (!this.recipientInfo) {
            return 'Inconnu';
        }
        switch (this.recipientInfo.type) {
            case 'ROLE_COSS_TUTOR':
            case 'ROLE_COSS_SCHOOL_REFERENT':
            case 'ROLE_COSS_SCHOOL_ADMIN':
            case 'ROLE_COSS_TEACHER':
            case 'ROLE_COSS_STUDENT':
                return this.recipientInfo.school?.name || _('recommendation.NO_SCHOOL');
            case 'RECIPIENT_PRO':
            case 'RECIPIENT_EXTERNAL_PRO':
            case 'RECIPIENT_SPORT_ASSO':
                return this.recipientInfo.company?.name || _('recommendation.NO_COMPANY');
            default:
                return _('recommendation.UNKNOWN');
        }
    }
}

export class SkillStatistics {
    answeringUsers: User[];
    answers: number;
    requests: number;
    average: string; // format: '2.5'
    graph: Graph;
    recommendations: Recommendation[];
    quality: QualityIndex;
    ranking: string; // format: '50%'
    scores: Score[];
    selfScore: string; // format: '2.5' (auto-evaluation)
    skill: Skill;
    strengths: any[];
    trainings: Training[];
    weaknesses: any[];
    weightedAverage: string; // format: '2.5'
    schoolAverage: string; // format: '2.5'
    feedbackAverage: string; // format: '2.5'
    schoolGrades: SchoolGrade[];
    unread_answers: number;
    missingProFeedbacks: number; // Nombre de feedbacks manquants de la part de Professionnels
    missingTutorFeedbacks: number; // Nombre de feedbacks manquants du tuteur
    missingTeacherFeedbacks: number; // Nombre de feedbacks manquants des profs/personnel établissement
    missingAllFeedbacks: number; // Nombre total de feedbacks manquants (toutes catégories confonfues)
    missingMadAllFeedbacks: number; // UNIQUEMENT POUR FAMILLE = MAD_SKILLS : nombre total de feedbacks manquants
    missingMadAssociativeFeedbacks: number; // UNIQUEMENT POUR FAMILLE = MAD_SKILLS : nb fb manquant Vie asso/Sportive

    constructor(obj?: Partial<SkillStatistics>) {
        if (obj) {
            Object.assign(this, obj);
            this.skill = new Skill(obj.skill);
            this.quality = obj.quality ? new QualityIndex(obj.quality) : null;
            this.scores = (obj?.scores || []).map(s => new Score(s));
            this.schoolGrades = (obj?.schoolGrades || []).map(s => new SchoolGrade(s));
            this.trainings = (obj?.trainings || []).map(t => new Training(t));
            if (typeof obj.graph === 'string') {
                this.graph = null;
            }
            this.answeringUsers = (obj?.answeringUsers || []).map(u => new User(u));
            this.recommendations = (obj?.recommendations || []).map(r => new Recommendation(r));
        }
    }

    getGoodBehaviors() {
        return this.scores.filter(s => +s.average >= 4);
    }

    getMediumBehaviors() {
        return this.scores.filter(s => +s.average >= 3 && +s.average < 4);
    }

    getBadBehaviors() {
        return this.scores.filter(s => +s.average < 3);
    }

    getSchoolGradeList(separator: string = ', '): string {
        return this.schoolGrades.map(g => g.title).join(separator);
    }

    get canSeeDetails() {
        // If skills has criteria starting with "note_" and mandatory => ok
        // If skill has closed feedback (from tutor) and 1 answer => ok
        if ((this.skill.skill_badge_criterions.find(c => c.modality.startsWith('note_') && c.mandatory))
            || (this.skill.skill_badge_criterions.find(c => c.modality === 'closed_feedbacks' && c.closed_feedbacks_detail === 'tutor' && c.modality)
                && this.answers > 0
            )
        ) {
            return true;
        }


        // Else need at least 2 answers to see details
        return this.answers > 1;
    }

    get qualityIndex(): 'A' | 'B' | 'C' {
        let feedbackIndex = null;
        const missingGrades = [];
        let hasGradeCriteria = false;
        // If no criteria, index = A
        if (!this.skill.skill_badge_criterions || this.skill.skill_badge_criterions.length === 0) {
            return this.quality.index;
        }
        // Compute index
        for (const criterion of this.skill.skill_badge_criterions) {
            if (!criterion.mandatory) {
                continue;
            }
            if (criterion.modality === 'opened_feedbacks') {
                feedbackIndex = this.quality.index;
            }
            if (criterion.modality === 'closed_feedbacks') {
                // Check for user type that answering users are enough
                const userType = criterion.closed_feedbacks_detail;
                const count = this.answeringUsers.filter(u => u.isType(userType)).length;
                feedbackIndex = count >= criterion.value ? 'A' : 'C';
            }
            if (criterion.mandatory && criterion.modality.startsWith('note_')) {
                hasGradeCriteria = true;
                const subject = criterion.subject.title;
                if (!this.schoolGrades.find((grade) => grade.title === subject && (grade.average * 4) >= criterion.value)) {
                    missingGrades.push(subject);
                }
            }
        }
        const gradeIndex = missingGrades.length > 0 ? 'C' : 'A';

        // Only feedbacks
        if (feedbackIndex && !hasGradeCriteria) {
            return feedbackIndex;
        }

        // Feedbacks and grades
        if (feedbackIndex && hasGradeCriteria) {
            if (feedbackIndex === 'A' && gradeIndex === 'A') {
                return 'A';
            } else if (feedbackIndex === 'B' && gradeIndex === 'A') {
                return 'B';
            } else {
                return 'C';
            }
        }

        // Only grades
        if (!feedbackIndex) {
            return gradeIndex;
        }

        return 'C';
    }
}

export interface BadgeGroup {
    badges?: Badge[];
    badge_type?: BadgeType;
    skill?: Skill;
    skills?: BadgeGroup[]; // Subgroup by skill
    family?: SkillFamily;
}

export class BadgeSkill {
    average: string;
    start: string;
    end: string;
    skill: Skill;
    id: number;

    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
            this.skill = new Skill(obj.skill);
        }
    }
}

export type SchoolShortName = 'coss'|'wsf'|'istec'|'isg'|'walt'|'icd'|'igs'|'uls'|'igs-alt'|'igs-rhg';
export type IstecBadgeName = 'istec-blue' | 'istec-green' | 'istec-pink' | 'istec-purple' | 'istec-turquoise' | 'istec-yellow';
export type WsfBadgeName = 'wsf-blue'| 'wsf-green'| 'wsf-pink'| 'wsf-purple' | 'wsf-turquoise' | 'wsf-yellow';
export type WaltBadgeName = 'walt-blue' | 'walt-green' | 'walt-pink' | 'walt-purple' | 'walt-turquoise' | 'walt-yellow';
export type IgsBadgeName = 'igs-blue' | 'igs-green' | 'igs-pink' | 'igs-purple' | 'igs-turquoise' | 'igs-yellow';
export type IgsAltBadgeName = 'igs-alt-blue' | 'igs-alt-green' | 'igs-alt-pink' | 'igs-alt-purple' | 'igs-alt-turquoise' | 'igs-alt-yellow';
export type IgsRhgBadgeName = 'igs-rhg-blue' | 'igs-rhg-green' | 'igs-rhg-pink' | 'igs-rhg-purple' | 'igs-rhg-turquoise' | 'igs-rhg-yellow';
export type UlsBadgeName = 'uls-blue' | 'uls-green' | 'uls-pink' | 'uls-purple' | 'uls-turquoise' | 'uls-yellow';
export type BadgeName = WsfBadgeName | IstecBadgeName | WaltBadgeName | IgsBadgeName | IgsAltBadgeName | IgsRhgBadgeName | UlsBadgeName | 'coss';

export class Badge {

    id: number;
    createdAt: string; // format: '2020-01-01T00:00:00.000Z'
    skillIds: number[];
    skills: BadgeSkill[];
    token: string;
    user: User;
    skill: Skill;
    average: string;
    end?: string;
    start?: string;

    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
            this.user = new User(obj.user);
            this.skills = (obj?.skills || []).map(s => new BadgeSkill(s));
            this.skill = obj.skill ? new Skill(obj.skill) : null;
        }
    }

    static groupByFamily(badges: Badge[]): BadgeGroup[] {
        const families = {};
        badges.forEach(b => {
            const family = b.getSkill()?.skill?.family || new SkillFamily({badge_type: 'soft_skills', title: 'Soft skills'});
            const familyTitle = family.title;
            if (!familyTitle) {
                return;
            }
            if (!families[familyTitle]) {
                families[familyTitle] = {
                    badges: [],
                    family,
                    badge_type: family.badge_type
                };
            }
            families[familyTitle].badges.push(b);
        });
        return Gamification.sortFamily(Object.values(families));
    }

    static groupByFamilyAndSkill(badges: Badge[]): BadgeGroup[] {
        const familiesAndSkills = {};
        badges.forEach(b => {
            const skill = b.skill || b.getSkill()?.skill;
            if (!skill) {
                return;
            }
            const family = skill.family || new SkillFamily({badge_type: 'soft_skills', title: 'Soft skills'});
            const familyTitle = family.title;
            // Group by family first
            if (!familiesAndSkills[familyTitle]) {
                familiesAndSkills[familyTitle] = {
                    family: family || new SkillFamily({badge_type: familyTitle, title: 'Soft skills'}),
                    badge_type: family.badge_type,
                    skills: []
                };
            }

            // Then group by category
            const skillGroup = familiesAndSkills[familyTitle].skills.find(s => s.skill.category.id === skill.category.id);
            if (skillGroup) {
                skillGroup.badges.push(b);
            } else {
                familiesAndSkills[familyTitle].skills.push({
                    skill,
                    badges: [b]
                });
            }
        });
        return Gamification.sortFamily(Object.values(familiesAndSkills));
    }

    getStartsAt(): string {
        return this.getSkill()?.start;
    }

    getEndsAt(): string {
        return  this.end || this.getSkill()?.end;
    }

    getSkill(): BadgeSkill {
        return this.skills?.length > 0 ? this.skills[0] : null;
    }

    getAverage(): string {
        return this.average === undefined ? this.getSkill()?.average : this.average;
    }

    getTitle(): string {
        return this.getSkill()?.skill?.title;
    }

    getSkillId(): number {
        return this.skillIds[0];
    }

    // isSoftSkill(): boolean {
    //     const type = this.getSkill()?.skill?.family?.badge_type;
    //     return type === 'soft_skills' || type === undefined;
    // }
    //
    // isHardSkill(): boolean {
    //     const type = this.getSkill()?.skill?.family?.badge_type;
    //     return type === 'hard_skills';
    // }
    //
    // isMadSkill() {
    //     const type = this.getSkill()?.skill?.family?.badge_type;
    //     return type === 'mad_skills';
    // }
}

export class Gamification {

    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
            this.myskills.statistics.all = (obj.myskills?.statistics?.all || []).map(s => new SkillStatistics(s));
            this.events.statistics.all = (obj.events?.statistics?.all || []).map(s => new SkillStatistics(s));
            this.badgesHistory = (obj?.badgesHistory || []).map(b => new Badge(b));
            this.wallets = (obj?.wallets || []).map(w => new Wallet(w));
        }

        console.log(obj.behaviors);
    }

    awards: any[]; // NOPE
    badgesHistory: Badge[];
    behaviors: {
        medium: BehaviorGroup[],
        bad: BehaviorGroup[],
        good: BehaviorGroup[]
    }; // Things to improve
    newsfeed: any[];
    contactsCount: 0;
    events: {
        statistics: {
            all: SkillStatistics[],
            byTitle: {
                all: any[],
                group: any[]
            }
        },
    };
    maxActiveWeeks: 0;
    myskills: {
        statistics: {
            all: SkillStatistics[]
        }
    };
    strengthsWeaknessesCumul: {
        strengths: {tag: string, count: number}[],
        weaknesses: {tag: string, count: number}[]
    };
    wallets: Wallet[];

    static sortFamily<T extends {family: SkillFamily}>(families: T[]): T[] {
        // Order is "Soft skills", "Hard skills", "Mad skills"
        const order = ['Soft skills', 'Hard skills', 'Mad skills'];
        return families.sort((a, b) => {
            return order.indexOf(a.family.title) - order.indexOf(b.family.title);
        });
    }

    getSkillStat(skillId: number): SkillStatistics {
        return this.myskills.statistics.all.find(s => s.skill.id === skillId);
    }

    getEventStat(skillId: number): SkillStatistics {
        return this.events.statistics.all.find(s => s.skill.id === skillId);
    }

    getEventStatsByTitle(skillId: number): { title: string, weightedAverage: string }[] {
        return this.events.statistics.byTitle.all.filter(s => s.skill.id === skillId);
    }
}

export interface Behavior {
    title: string;
    score: string;
}

export interface BehaviorGroup {
    title: string;
    behaviors: Behavior[];
}
